import React from "react";
import "./Approch.css";

const Approch = () => {
  return (
    <div>
      <div className="entry big">
        <div className="container">
          <h2
            className="section-title1 wow fadeInUp"
            style={{ visibility: "visible", animationName: "fadeInUp" }}
            data-aos="fade-up"
          >
            Our 360° Approach to Effective Software Development
          </h2>
          <div className="row">
            <section className="m-howItWork">
              <div className="m-howItWork__content">
                <div className="m-howItWork__list">
                  <article
                    className="m-howItWork__listItem aos-init aos-animate"
                    data-aos="fade-right"
                  >
                    <div
                      className="m-howItWork__listImage m-howItWork__listImage--instruct"
                      data-aos="fade-up"
                    ></div>
                    <div className="m-howItWork__listText" data-aos="fade-up">
                      <span className="m-howItWorkhdr">Backlog Planning</span>
                      <br />
                      We take our time to understand your requirements and
                      create a strategic approach to your project that
                      prioritizes tasks.
                    </div>
                  </article>
                  <article
                    className="m-howItWork__listItem aos-init aos-animate"
                    data-aos="fade-right"
                    data-aos-delay="100"
                  >
                    <div
                      className="m-howItWork__listImage m-howItWork__listImage--writers"
                      data-aos="fade-up"
                    ></div>
                    <div className="m-howItWork__listText" data-aos="fade-up">
                      <span className="m-howItWorkhdr">Planning</span>
                      <br />
                      Bluewave formulate high-impact software development plans
                      that maximize the degree of success of your mobile apps
                      and software.
                    </div>
                  </article>
                  <article
                    className="m-howItWork__listItem aos-init aos-animate"
                    data-aos="fade-right"
                    data-aos-delay="200"
                  >
                    <div
                      className="m-howItWork__listImage m-howItWork__listImage--rating"
                      data-aos="fade-up"
                    ></div>
                    <div className="m-howItWork__listText" data-aos="fade-up">
                      <span className="m-howItWorkhdr">Development</span>
                      <br />
                      Bluewave creates and develops your software, integrating
                      innovative features that elevate its functionality and
                      user experience.
                    </div>
                  </article>
                  <article
                    className="m-howItWork__listItem aos-init aos-animate"
                    data-aos="fade-right"
                    data-aos-delay="300"
                  >
                    <div
                      className="m-howItWork__listImage m-howItWork__listImage--payment"
                      data-aos="fade-up"
                    ></div>
                    <div className="m-howItWork__listText" data-aos="fade-up">
                      <span className="m-howItWorkhdr">Quality Assurance</span>
                      <br />
                      We follow our in-house stringent software testing
                      procedures to ensure that you get the most out of your
                      software.
                    </div>
                  </article>
                  <article
                    className="m-howItWork__listItem aos-init aos-animate"
                    data-aos="fade-right"
                    data-aos-delay="400"
                  >
                    <div
                      className="m-howItWork__listImage m-howItWork__listImage--paper"
                      data-aos="fade-up"
                    ></div>
                    <div className="m-howItWork__listText" data-aos="fade-up">
                      <span className="m-howItWorkhdr">
                        Review and Deployment
                      </span>
                      <br />
                      We run your software in its ideal environment of operation
                      to optimize performance before making it available for use
                      by your target audience.
                    </div>
                  </article>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Approch;
