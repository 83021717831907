import React from "react";
import "./HServices.css";
import "aos-animations/dist/animations.min.css";
import "aos-animations/dist/animations.min.js";

const HServices = () => {
  return (
    <>
      <section id="services" className="services ">
        <div className="container" data-aos="fade-up" id="Bannerid">
          <header className="section-header" data-aos="fade-up">
            <h3>Services</h3>
            <p>
              Bluewave offer the best Services for your Business and We value
              professionalism and collaborative teamwork that is totally
              customer-focused. We always pay attention to our clients’ needs
              and look for ways to improve them.
            </p>
          </header>
          <div className="row g-3">
            <div
              className="col-md-6 col-lg-4"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <div className="box">
                <div className="icon" style={{ background: " #fff0da" }}>
                  <i
                    className="bx bx-mobile-vibration"
                    style={{ color: "#e98e06" }}
                  ></i>
                </div>
                <h4 className="title22">
                  <a href="#Services">App Development</a>
                </h4>
                <p className="description">
                  Bluewave are most trusted mobile app development company
                  offering full-cycle mobility solutions from initial
                  conceptualization of your application to the final deployment
                  in the app store & maintenance.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 wow"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div className="box">
                <div className="icon" style={{ background: "  #eafde7" }}>
                  <i className="bx bxs-game" style={{ color: "#41cf2e" }}></i>
                </div>
                <h4 className="title22">
                  <a href="#Services">Game Development</a>
                </h4>
                <p className="description">
                  we create intuitive gaming apps that have the true potential
                  to be the next craze of this era. With us, you can deliver
                  immersive and engaging games to your users.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4"
              data-aos="zoom-in"
              data-aos-delay="300"
            >
              <div className="box">
                <div className="icon" style={{ background: "  #e6fdfc" }}>
                  <i className="bx bx-pen" style={{ color: "#3fcdc7" }}></i>
                </div>
                <h4 className="title22">
                  <a href="#Services">Graphic Designing</a>
                </h4>
                <p className="description">
                  Bluewave is a professional Graphic Design and Development &
                  Social Media Marketing Company and we provide you
                  logo,drafts,portfolio,posts and much more.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 wow bounceInUp "
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div className="box">
                <div className="icon" style={{ background: "#fceef3" }}>
                  <i
                    className="bx bx-code-block"
                    style={{ color: "#ff689b" }}
                  ></i>
                </div>
                <h4 className="title22">
                  <a href="#Services">Web Develoment</a>
                </h4>
                <p className="description">
                  As one of the best web development companies in worldwide,
                  Keep business solutions provide a host of web development
                  services for global clients. Take a look at the broad range of
                  web development services.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <div className=" box">
                <div className="icon" style={{ background: "  #e1eeff" }}>
                  <i
                    className="bx bxs-check-shield"
                    style={{ color: "#2282ff" }}
                  ></i>
                </div>
                <h4 className="title22">
                  <a href="#Services">Security</a>
                </h4>
                <p className="description">
                  Bluewave provides businesses with application security through
                  steps including application design review, application code
                  review, and secure application development.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HServices;
