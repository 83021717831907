import React from "react";
import "./port.css";
import Bank from "../../assets/images/PortfolioUi/Bank.jpg";
import Doctor from "../../assets/images/PortfolioUi/Doctor.jpg";
import Music from "../../assets/images/PortfolioUi/Music.jpg";
import Pdf from "../../assets/images/PortfolioUi/Pdf.jpg";
import Sushi from "../../assets/images/PortfolioUi/Sushi.jpg";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

const Port = () => {
  const Navigate = useNavigate();

  const Portfolio = () => {
    Navigate("/Portfolio");
  };

  let colorSettings = {
    section: [
      "linear-gradient(to right, rgb(139 192 88) 0%, rgb(1, 162, 169) 100%)",
      "linear-gradient(to right, rgb(100 224 255) 0%, rgb(158 145 252) 100%)",
      "linear-gradient(to right, rgb(25 29 26) 0%, rgb(0 124 51) 100%)",
      "linear-gradient(to right, rgb(200 30 30) 0%, rgb(180 180 180) 100%)",
      "linear-gradient(to right, #a7a7a7 0%, #343434 100%)",
    ],
  };
  const changeColors = (slide) => {
    document.querySelector(".inner-section-portfolio").style.background =
      colorSettings.section[slide];
  };


  let settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    loop: false,
    swipe: true,
    autoplay: false,
    lazyLoad: "progressive",
    arrows: false,
    beforeChange: (current, nextSlide) => changeColors(nextSlide),

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 426,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <>
      <div id="hportfolio">
        <div className="container main-section-portfolio" id="port" >
          <div className="inner-section-portfolio" >
            <div className=" left-wrap">
              <div className="question-title">
                <span>What have we been working on?</span>
              </div>
              <div className="paragraph-wrapper">
                <p>
                  A selection of projects that we have designed in accordance
                  with client requirements, Using the latest trends, and user
                  preferences.
                </p>
              </div>
              <div className="btn-more-projects" onClick={Portfolio}>
                <button> More Projects</button>
              </div>
            </div>
            <div className="right-wrap">
              <Slider
                {...settings}
                style={{ outline: "!important" }}
                className="slider-new-portfolio test"
                id="your_slider"
              >
                <div data-index="1" data-color="#F44336">
                  <img src={Bank} alt="" />
                </div>
                <div data-index="2" data-color="#7E57C2">
                  <img src={Doctor} alt="" />
                </div>
                <div data-index="3" data-color="#F44336">
                  <img src={Music} alt="" />
                </div>
                <div data-index="4" data-color="#009688">
                  <img src={Pdf} alt="" />
                </div>
                <div data-index="5" data-color="#7E57C2">
                  <img src={Sushi} alt="" />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Port;
