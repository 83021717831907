import React, { useEffect, useState } from "react";
import BackButton from "./components/BackToTop/BackToTop";
import Banner from "./components/Banner/Banner";
import Footer from "./components/Footer/Footer";
import Gallery from "./components/Gallery/gallery";
import Navbar from "./components/Navbar/Navbar";
import Questions from "./components/Questions/Questions";
import Testimonials from "./components/Testimonials/Testimonials";
import Approch from "./components/Approch/Approch";
import Specialties from "./components/Specialties/Specialties";
import Port from "./components/port/Port";
import "./Homepage.css";
import HServices from "./components/Servicess/HServices";
import Talk from "./components/Talk/Talk";
import About from "./components/About/HAbout";

const Homepage = () => {
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    getloading();
  }, []);

  const getloading = () => {
    setTimeout(() => {
      setLoader(false);
    }, 500);
  };

  return (
    <>
      {loader && <div id="preloader"></div>}
      <Navbar />
      <Banner />
      <About />
      <Specialties />
      <Approch />
      <Questions />
      <HServices />
      <Testimonials />
      <Port />
      <Gallery />
      <Talk />
      <Footer />
      <BackButton />
    </>
  );
};

export default Homepage;
