import React from "react";
import "./HAbout.css";
import p1 from "../img/businessman.jpg";
import p2 from "../img/about-vision.jpg";
import p3 from "../img/about-plan.jpg";

const HAbout = () => {
  return (
    <div className="about_wrp">
      <section id="about">
        <div className="container" data-aos="fade-up">
          <header className="section-header">
            <h3>About Us</h3>
            <p>
              Bluewave offer the best Services for your Business and We value
              professionalism and collaborative teamwork that is totally
              customer-focused. We always pay attention to our clients’ needs
              and look for ways to improve them.
            </p>
          </header>

          <div className="row about-cols" style={{ padding: 0 }}>
            <div
              className="col-sm-12 col-md-6 col-lg-4"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="about-col">
                <div className="img">
                  <img src={p1} alt="" className="img-fluid" />
                  <div className="icon">
                    <i className="bi bi-bar-chart"></i>
                  </div>
                </div>
                <h2 className="title">
                  <a href="/">Our Mission</a>
                </h2>
                <p>
                  Our Mission is simple, to create and develop apps & webs that
                  match your ideas. we meant although we are not magicians we
                  make your dreams come true.
                </p>
              </div>
            </div>

            <div
              className="col-sm-12 col-md-6 col-lg-4"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="about-col">
                <div className="img">
                  <img src={p3} alt="" className="img-fluid" />
                  <div className="icon">
                    <i className="bx bx-brightness-half"></i>
                  </div>
                </div>
                <h2 className="title">
                  <a href="/">Our Plan</a>
                </h2>
                <p>
                  Our goal is to develop solutions that solve complex business
                  issues while providing an excellent customer experience.
                </p>
              </div>
            </div>

            <div
              className="col-sm-12 col-md-6 col-lg-4"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="about-col">
                <div className="img">
                  <img src={p2} alt="" className="img-fluid" />
                  <div className="icon">
                    <i className="bi bi-calendar4-week"></i>
                  </div>
                </div>
                <h2 className="title">
                  <a href="/">Our Vision</a>
                </h2>
                <p>
                  Build the best apps and web that help in day-to-day life.
                  business to inspire people and create solutions for real-life
                  problems.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HAbout;
