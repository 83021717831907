import React, { useEffect, useState } from "react";
import BackToTop from "../BackToTop/BackToTop";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import "./About1.css";
import vision from "../../assets/images/vision-01.png";
import mission from "../../assets/images/mission-01.png";
import Subbanner from "../Subbanner/Subbanner";
import a1 from "../../assets/images/a1.png";
import a2 from "../../assets/images/a2.png";
import a3 from "../../assets/images/a3.png";
import a4 from "../../assets/images/a4.png";

const About = () => {
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    getloading();
  }, []);

  const getloading = () => {
    setTimeout(() => {
      setLoader(false);
    }, 500);
  };
  return (
    <>
      <Navbar />
      {loader && <div id="preloader"></div>}
      <Subbanner pagename={"About Us"} />
      <section id="Benefits">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="Benefits-detail">
                <div className="Benefits-details">
                  <div className="Benefits-title">
                    <div className="sort-title">WHY CHOOSE US</div>
                    <h3>Why Choose Bluewave</h3>
                  </div>
                </div>
                <div className="Benefits-text-editor ">
                  <p>
                    Bluewave, a well-known software application development
                    firm, is known for meeting current business demands and
                    offering best-in-class solutions at a reasonable price all
                    over the world. Here are a few reasons why we’re the ideal
                    choice for developing software for your company:
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="Choose-part">
                <div className="Choose-image-box">
                  <figure className="Choose-image">
                    <img
                      width="70"
                      height="70"
                      src={a1}
                      className="attachment-full size-full"
                      alt=""
                    />
                  </figure>
                  <div className="Choose-content">
                    <h4>Customer Satisfaction</h4>
                    <p className="elementor-image-box-description">
                      Smart coding is avoiding all technological hazards as soon
                      as possible while creating code and making it
                      fault-resistant. Before developing the code, we plan the
                      activities and how users could react.
                    </p>
                  </div>
                </div>
              </div>
              <div className="Choose-part">
                <div className="Choose-image-box">
                  <figure className="Choose-image">
                    <img
                      width="70"
                      height="70"
                      src={a2}
                      className="attachment-full size-full"
                      alt=""
                    />
                  </figure>
                  <div className="Choose-content">
                    <h4>Agile Procedure</h4>
                    <p className="elementor-image-box-description">
                      We use the Agile approach and hold scrum events on a
                      regular basis to improve our processes and procedures and
                      give the best outcomes.
                    </p>
                  </div>
                </div>
              </div>
              <div className="Choose-part">
                <div className="Choose-image-box">
                  <figure className="Choose-image">
                    <img
                      width="70"
                      height="70"
                      src={a3}
                      className="attachment-full size-full"
                      alt=""
                    />
                  </figure>
                  <div className="Choose-content">
                    <h4>Competitive Pricing</h4>
                    <p className="elementor-image-box-description">
                      To ensure the lowest pricing in our category, we offer an
                      optimal blend of cost-effective rates and exceptional
                      quality.
                    </p>
                  </div>
                </div>
              </div>
              <div className="Choose-part">
                <div className="Choose-image-box">
                  <figure className="Choose-image">
                    <img
                      width="70"
                      height="70"
                      src={a4}
                      className="attachment-full size-full"
                      alt=""
                    />
                  </figure>
                  <div className="Choose-content">
                    <h4>Integrity & Transparency</h4>
                    <p className="elementor-image-box-description">
                      We value your ideas and vision, give you every project
                      detail frequently, and consider your valuable input as
                      needed.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="type-background element-12"
        style={{ marginTop: "100px" }}
      >
        <div className="background-overlay"></div>
        <section className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="about-content">
                <div className="widget-wrap">
                  <div className="image-box-wrapper">
                    <figure className="image-box-img1">
                      <img
                        width="80"
                        height="80"
                        src={vision}
                        className="attachment-full size-full"
                        alt=""
                      />
                    </figure>
                    <div className="image-box-content1">
                      <h3>Our Vision</h3>
                      <p>
                        Build the best apps and web that help in day-to-day
                        life. business to inspire people and create solutions
                        for real-life problems.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 ">
              <div className="about-content">
                <div className="widget-wrap">
                  <div className="image-box-wrapper">
                    <figure className="image-box-img1">
                      <img
                        width="90"
                        height="80"
                        src={mission}
                        className="attachment-full size-full"
                        alt=""
                      />
                    </figure>
                    <div className="image-box-content1">
                      <h3>Our Mission</h3>
                      <p>
                        Our Mission is simple, to create and develop apps & webs
                        that match your ideas. we meant although we are not
                        magicians we make your dreams come true.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

      <Footer />
      <BackToTop />
    </>
  );
};

export default About;
